import React, {  Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

// components
// import Root from './Root';

// lazy load all the views


// home
const Home = React.lazy(() => import('../pages/Home'));
const About = React.lazy(() => import('../pages/About'));
const Products = React.lazy(() => import('../pages/Products'));
const Education = React.lazy(() => import('../pages/Education'));
const Contact = React.lazy(() => import('../pages/Contact'));


const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    return useRoutes([
        {
            // root route
            path: '/',
            element: <LoadComponent component={Home} />,
        },
        {
            // public routes
            path: '/',
            children: [
                // {
                //     path: '',
                //     element: <LoadComponent component={Home} />,
                // },
                {
                    path: 'about',
                    element: <LoadComponent component={About} />,
                },
                {
                    path: 'products',
                    element: <LoadComponent component={Products} />,
                },
                {
                    path: 'educational',
                    element: <LoadComponent component={Education} />,
                },
                {
                    path: 'contact',
                    element: <LoadComponent component={Contact} />,
                },
               
        
            ],
        },
        
    ]);
};

export default AllRoutes;
