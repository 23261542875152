import { useEffect } from 'react';

import AOS from 'aos';

// routes
import Routes from './routes/Routes';
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/templatemo.css';
import './assets/css/custom.css';

import './assets/css/fontawesome.min.css';



const App = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    return <Routes />;
};

export default App;